import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

class Item extends Component {

    data = this.props.Data;
    language = this.props.Language;

    state = {
        isLoading: true
    };
    
    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 2500);
    }

    render() {

        const { isLoading } = this.state;

        return (
            <>
                {
                    isLoading ? 
                    <SkeletonTheme  baseColor="#202020" highlightColor="#444" >
                        <Skeleton height={150} containerClassName="flex-1" />
                    </SkeletonTheme>
                    : 
                    <Link to={"/menu/" + this.data['id']} className="category-item" >
                            
                            {
                                this.props.Index % 2 == 0 ?
                                <>
                                    <div className='category-img' style={ { backgroundImage :`url(${this.data.cover})`, backgroundSize: 'cover', backgroundPosition: 'center' , filter:"brightness(0.9)"} }></div>

                                    <div className='category-text' style={{borderBottomRightRadius: '20px', borderTopRightRadius: '20px'}}>
                                        {
                                            this.props.Language == "en" ? this.data['name_eng']
                                            : this.props.Language == "krd" ? this.data['name_krd']
                                            : this.data['name_ar']
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    <div className='category-text' style={{borderBottomLeftRadius: '20px', borderTopLeftRadius: '20px'}}>
                                        {
                                            this.props.Language == "en" ? this.data['name_eng']
                                            : this.props.Language == "krd" ? this.data['name_krd']
                                            : this.data['name_ar']
                                        }
                                    </div>
                                    <div className='category-img' style={ { backgroundImage :`url(${this.data.cover})`, backgroundSize: 'cover', backgroundPosition: 'center' , filter:"brightness(0.9)"} }></div>
                                </>
                            }

                            

                            
                    </Link>
                }
                
            </>
        );
    }
}

export default Item;