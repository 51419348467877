import React, { Component } from 'react';
import Imgloading from './itemloading';
import Swal from 'sweetalert2'

class List extends Component {

    data = this.props.Data;
    language = this.props.Language;

    showImage = (img) => {
        Swal.fire({
            imageUrl: img,
            imageAlt: 'image',
            backdrop:false,
          })
    }

    render() {
        return (
            <div className="style-list">
            <div className="side-left">
                <h5>
                    {/* {this.data.order??0} -  */}
                    {
                        this.props.Language == "en" ? this.data.name_eng
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                    } 
                </h5>
                <p> 
                    {
                        this.props.Language == "en" ? this.data.desc_eng
                        : this.props.Language == "krd" ? this.data.desc_krd
                        : this.data.desc_ar
                    } 
                </p>
                <div className='price'> 
                    {
                        (Array.isArray(this.data.prices) && this.data.prices.length)? 
                            this.data.prices.map(
                                item => 
                                    <span> 
                                        {
                                            this.props.Language == "en" ? item.name_en
                                            : this.props.Language == "krd" ? item.name_krd
                                            : item.name_ar
                                        } 
                                        { item.price } 
                                    </span> )
                        :
                        <span> {this.data.price}  </span> 
                    }
                </div>
            </div>
            <div className="side-right" onClick={ () => this.showImage(this.data.image) }>
                <Imgloading Img={this.data.image} />
            </div>
        </div>
        );
    }
}

export default List;